import React from 'react';
import classnames from 'classnames';

import style from './Container.sass';

export interface Props {
  className?: string;
  full?: boolean;
}

const Container: React.FC<Props> = ({ full, className, ...rest }) => (
  <div {...rest} className={classnames(style.root, { [style.full]: full }, className)} />
);

export default Container;
