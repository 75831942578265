import React from 'react';

export type IsComponent =
  | keyof JSX.IntrinsicElements
  | React.JSXElementConstructor<any>
  | React.ComponentType<any>
  | React.Component<any>;

export type IsComponentProps<T> = T extends keyof JSX.IntrinsicElements | React.JSXElementConstructor<any>
  ? React.ComponentProps<T>
  : T extends React.ComponentType<infer P> | React.Component<infer P>
  ? JSX.LibraryManagedAttributes<T, P>
  : T extends string
  ? any
  : never;

export type Props<T extends IsComponent> = Omit<IsComponentProps<T>, 'is'> & {
  is: T;
};

class Is<T extends IsComponent> extends React.PureComponent<Props<T>> {
  render() {
    const { is: Component, ...rest } = this.props;

    return <Component {...rest} />;
  }
}

export default Is;
