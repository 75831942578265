import React from 'react';
import classnames from 'classnames';

import Is, { Props as IsProps, IsComponent } from '@/components/Is';

import style from './Typography.sass';

export type Props<P extends IsComponent> = IsProps<P> & {
  type?: 'h1' | 'h2' | 'h3' | 'p' | 'small';
  color?: 'primary' | 'secondary' | 'ternary' | 'accent' | 'light';
  underline?: boolean;
  block?: boolean;
  className?: string;
};

class Typography<P extends IsComponent> extends React.PureComponent<Props<P>> {
  static defaultProps: Pick<Props<any>, 'is' | 'color'> = {
    is: 'span',
    color: 'primary'
  };

  render() {
    const { type, color, underline, block, ...rest } = this.props;

    return (
      // @ts-ignore
      <Is
        {...rest}
        className={classnames(
          style.root,
          {
            [style[type!]]: !!type,
            [style[color!]]: !!color,
            [style.underline]: underline,
            [style.block]: block
          },
          this.props.className
        )}
      />
    );
  }
}

export default Typography;
