import React from 'react';
import classnames from 'classnames';

import { WithSize, Magnitude } from '@/constants';
import { getStylesWithSize } from '@/util';

import style from './Spacer.sass';

export interface Props extends WithSize<Magnitude> {
  className?: string;
  type?: 'padding' | 'margin';
}

const Spacer: React.FC<Props> = (props) => {
  return (
    <React.Fragment>
      {React.Children.map((props.children as React.ReactElement) || <div />, (child) => {
        return React.cloneElement(child, {
          className: classnames(
            getStylesWithSize(
              (size) => (style as any)[`${size}${props.type || 'padding'}${props[size] || 0}`],
              (size) => props[size] != null
            ),
            child.props.className
          )
        });
      })}
    </React.Fragment>
  );
};

export default Spacer;
